import axios from 'axios';
import { resolve } from 'util/resolve';

export const API = process.env.REACT_APP_API_ENDPOINT;

axios.defaults.withCredentials = true;

export async function vendorSignIn(email: string, otp: string) {
    return await resolve(axios.post(`${API}/user/vendor-sign-in`, {email: email, otp: otp}).then(res => res.data))
}

export async function organisationSignIn(email: string, otp: string) {
    return await resolve(axios.post(`${API}/user/organisation-sign-in`, {email: email, otp: otp}).then(res => res.data))
}

export async function organisationRequestOTP(email: string) {
    return await resolve(axios.post(`${API}/user/organisation-request-otp`, {email: email}).then(res => res.data));
}

export async function vendorRequestOTP(email: string) {
    return await resolve(axios.post(`${API}/user/vendor-request-otp`, {email: email}).then(res => res.data));
}