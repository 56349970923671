import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {createTheme} from "theme/index";
import { Route, Routes } from 'react-router-dom';
import PortalSelection from 'components/PortalSelection';
import Login from 'components/Login';
import Verify from 'components/Verify';
import Container from 'components/Container';

export default function App() {

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  return (
    <ThemeProvider theme={createTheme({mode: "light", responsiveFontSizes: true})}>
          <Container>
            <Routes>
                <Route path="/" element={<PortalSelection />} />
                <Route path="/vendor/login" element={<Login setEmail={(value) => setEmail(value)} setPhone={(value) => setPhone(value)}/>} />
                <Route path="/organisation/login" element={<Login setEmail={(value) => setEmail(value)} setPhone={(value) => setPhone(value)}/>} />     
                <Route path="/vendor/verify" element={<Verify email={email} phone={phone} />}/>
                <Route path="/organisation/verify" element={<Verify email={email} phone={phone} />}/>     
            </Routes>
          </Container>
    </ThemeProvider>
  )
}