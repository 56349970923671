import { Box, Button, Divider, Typography } from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleIcon from '@mui/icons-material/People';
import { useNavigate } from 'react-router-dom';

const PortalSelection = () => {
    
    let navigate = useNavigate();
    
    return (
            <>
              <Typography variant='h5'>
                Select Portal
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  flexGrow: 1,
                  mt: 5,
                }}
              >
                <Box 
                  onClick={() => navigate("/organisation/login")}
                  sx={{
                  cursor: "pointer",
                  borderRadius: 3, 
                  width: "100%", 
                  height: "300px",
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: "1px solid #E6E8F0",
                  px: 2,
                  pb: 1
                }}>
                    <CorporateFareIcon sx={{color: "primary.light", fontSize: "80px"}}/>
                    <Box sx={{mb: 2, textAlign: "center"}}>
                      <Typography color="neutral.light" gutterBottom variant="h6">
                        Organisation
                      </Typography>
                      <Typography variant="subtitle2">
                        Choose this is you are a facilities manager, building owner or building manager.
                      </Typography>  
                    </Box>
                    <Button onClick={() => navigate("/organisation/login")} variant="contained"  color="secondary">
                      Select
                    </Button>
                </Box>
                <Box 
                   onClick={() => navigate("/vendor/login")}
                  sx={{
                    cursor: "pointer",
                    ml: 2,
                    borderRadius: 3, 
                    width: "100%", 
                    height: "300px",
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: "1px solid #E6E8F0",
                    px: 2,
                    pb: 1
                  }}>
                    <PeopleIcon sx={{color: "primary.light", fontSize: "80px"}}/>
                    <Box sx={{mb: 2, textAlign: "center"}}>
                      <Typography color="primary.light" gutterBottom variant="h6">
                          Vendor
                      </Typography>
                      <Typography variant="subtitle2">
                        Choose this is you are a subcontractor, supplier or engineer.
                      </Typography>  
                    </Box>
                    <Button onClick={() => navigate("/vendor/login")} variant="contained"  color="secondary">
                      Select
                    </Button>
              </Box>
              </Box>
              <Divider sx={{mt: 3, mb: 1 }} />
            </>
    )
  }
  
  export default PortalSelection;
  