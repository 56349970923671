import { useEffect, useState } from 'react'
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleIcon from '@mui/icons-material/People';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Box, Button, FormHelperText, TextField, Alert, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { organisationRequestOTP, vendorRequestOTP } from 'util/api';
import MuiPhoneNumber from 'material-ui-phone-number';

const Login = (props: {
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
}) => {

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      showEmail: true,
      showPhone: false,
      submit: null
    },
    validationSchema: Yup.object({
      showEmail: Yup.boolean(),
      showPhone: Yup.boolean(),
        // phone: Yup
        // .string()
        // .required("Phone is required")
        // .matches(phoneRegExp, 'Phone number is not valid'),
        // @ts-ignore
      email: Yup
        .string()
        .email("Must be a valid email address")
        .required("Must enter email address")
    }),
    onSubmit: async (values, helpers): Promise<void> => {

      var res;
      
      if (selectedPortal === "ORGANISATION") {
        res = await organisationRequestOTP(values.email);
      } else {
        res = await vendorRequestOTP(values.email);
      }
      
      if (res.error) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
        if (res.error.response.status === 401) {
          helpers.setErrors({ submit: res.error.response.data.message});
        } else {
          helpers.setErrors({ submit: res.error.message});
        }
      } 
      else {
        if (selectedPortal === "ORGANISATION") {
          props.setEmail(formik.values.email);
          props.setPhone(formik.values.phone);
          navigate("/organisation/verify");
        } else {
          props.setEmail(formik.values.email);
          props.setPhone(formik.values.phone);
          navigate("/vendor/verify");
        }
      }
    }
  });

  const [ selectedPortal, setSelectedPortal ] = useState<"ORGANISATION" | "VENDOR">("ORGANISATION");
  let navigate = useNavigate();
  const path = useLocation().pathname.split('/')[1];
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    // Wait for 1 second
    setIsLoading(true);
    formik.setValues({
      email: '',
      phone: '',
      showEmail: true,
      showPhone: false,
      submit: null
    });    
    if (path === "vendor") {
      setSelectedPortal("VENDOR");
    } else {
      setSelectedPortal("ORGANISATION");
    }
    setTimeout(() => {      
      setIsLoading(false);
    }, 1000);

  }, [path]);

  return (
    <>
      {
        isLoading ?
        <Box sx={{ 
          my: 4, 
          width: "100%",
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CircularProgress />
        </Box>
      :
      <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {selectedPortal === "ORGANISATION" ? <CorporateFareIcon sx={{ fontSize: "60px"}}/>
        : <PeopleIcon sx={{fontSize: "60px"}}/>}
        <Typography variant='h5'>
          {selectedPortal === "ORGANISATION" && "Organisation"}
          {selectedPortal === "VENDOR" && "Vendor"}
          {" "}
          Portal
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          flexGrow: 1,
          mt: 2
        }}
      >
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
      {
        formik.values.showPhone &&
        <MuiPhoneNumber
          fullWidth
          onlyCountries={['au', 'ae']}
          error={Boolean(formik.touched.phone && formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          countryCodeEditable={false}
          label="Phone Number"
          margin="normal"
          variant="outlined"
          name="phone"
          onBlur={formik.handleBlur}
          InputLabelProps={{shrink: true}}
          defaultCountry={'au'} 
          onChange={(value) => {formik.setFieldValue("phone", value)}} 
          value={formik.values.phone}
        />
      }
      {formik.values.showEmail && 
        <TextField
          InputLabelProps={{shrink: true}}
          autoFocus
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
      }
      <Box sx={{ mt: 3, mb:1 }}>
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          color="primary"
          variant="contained"
        >
          Log In
        </Button>
      </Box>
      </form>
      </Box>
      {formik.errors.submit && 
      <Box sx={{ mt: 2, width: "100%" }}>
        <Alert severity="error">
          {formik.errors.submit}
        </Alert>
      </Box>}
      <Box sx={{mt: 3, width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
        <Button disabled={formik.isSubmitting || isLoading} onClick={(e) => {
          if (selectedPortal === "VENDOR") {
            navigate("/organisation/login");
          } else {
            navigate("/vendor/login");
          }
        }} color="primary" variant='outlined'>
          {selectedPortal === "VENDOR" ? "Switch to Organisation Portal" : "Switch to Vendor Portal" }
        </Button>
      </Box>
    </>}
    </>
  )
}

export default Login;
