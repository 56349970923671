export interface IResolve {
    data: any,
    error: any
} 

export async function resolve(promise: Promise<IResolve>) {
    const resolved:IResolve = {
        data: null,
        error: null
    }
    try {
        resolved.data = await promise
    } catch (e) {
        resolved.error = e
    }
    return resolved;
}