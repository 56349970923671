import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Typography,
  Box,
  Button,
  Alert
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMounted } from 'components/hooks/use-mounted'
import { organisationSignIn, vendorSignIn } from 'util/api'
import { MuiOtpInput } from 'mui-one-time-password-input'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
const Verify = (props: {
    email: string,
    phone: string
}) => {

    const [ selectedPortal, setSelectedPortal ] = useState<"ORGANISATION" | "VENDOR">("ORGANISATION");

    let navigate = useNavigate();
  
    const path = useLocation().pathname.split('/')[1];
    
    useEffect(() => {
      if (path === "vendor") {
        setSelectedPortal("VENDOR");
      } else {
        setSelectedPortal("ORGANISATION");
      }
    }, [path]);

    const [otp, setOtp] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        async function signIn() {
            setSubmitting(true);
            if (selectedPortal === "ORGANISATION") {
                const res = await organisationSignIn(props.email, otp);
                setSubmitting(false);
                if (res.error) {
                    setError(res.error.message);
                } else {
                    window.location.href = "/";
                }
            } else if (selectedPortal === "VENDOR") {
                const res = await vendorSignIn(props.email, otp);
                setSubmitting(false);
                if (res.error) {
                    setError(res.error.message);
                } else {
                    window.location.href = "/";
                }
            }
        }
      
        if (otp.length === 6) { 
            signIn();
        }
    }, [otp]);

    return (
        <>
        <Box
            sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
            }}
        >
            <MailOutlineIcon sx={{color: "primary.light", fontSize: "80px", mb:2}}/>
            <Typography variant='h5'>
                We just emailed you
            </Typography>
            <Typography sx={{mb:2, mt:1}} variant='body2'>Please enter the code we sent to <b>{props.email}</b></Typography>
            <MuiOtpInput    
                sx={{my: 3}} 
                length={6} 
                value={otp} 
                onChange={(value) => {
                   if(error) setError('');
                   setOtp(value);
                }} 
            />
            {error && <Alert sx={{width: "100%"}} severity="error">{error}</Alert>}
            <Button 
                onClick={async () => {
                    setSubmitting(true);
                    if (selectedPortal === "ORGANISATION") {
                        const res = await organisationSignIn(props.email, otp);
                        setSubmitting(false);
                        if (res.error) {
                            setError(res.error.message);
                        } else {
                            window.location.href = "/";
                        }
                    } else if (selectedPortal === "VENDOR") {
                        const res = await vendorSignIn(props.email, otp);
                        setSubmitting(false);
                        if (res.error) {
                            setError(res.error.message);
                        } else {
                            window.location.href = "/";
                        }
                    }
                }}  
                disabled={submitting} 
                sx={{mt: 2}} 
                variant='contained' 
                size="large"
                color="secondary"
            >
                Verify
            </Button>
            <Box sx={{mt: 3}}>
                <Button disabled={submitting} onClick={() => navigate(-1)} variant="text">
                    Change email
                </Button>
            </Box>
        </Box>
    </>
  )
}

export default Verify;
