// import background from 'components/background.svg';
import { Box, Card, Container as MuiContainer } from '@mui/material'
// import { ReactNode } from 'react'
import Logo from './Logo';

type Props = {
    children?: React.ReactNode
};

const Container = (props:Props) => {
        
    return (
        <Box
          component='main'
          sx={{
            backgroundColor: 'neutral.100',
            // backgroundImage: `url(${background})`,
            backgroundSize: "200px 200px",
            display: 'flex',
            flexAlign: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: "100%"
          }}
        >
          <MuiContainer
            maxWidth='sm'
          >
              <Card elevation={16} sx={{ p: 4 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  mb: 2
                }}
              > 
                <Box sx={{my: 2}}>
                  <Logo/>
                </Box>
                {props.children}
            </Box>
            </Card>
          </MuiContainer>
        </Box>
)}
export default Container