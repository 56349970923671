import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as AudomasLogo } from 'components/audomas.svg';

const Logo = () => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    }}>
      <Box sx={{
        marginRight: {
          xs: "10px",
          sm: "10px",
        },
        marginTop: {
          xs: "5px",
          sm: "7px",
          md: "9px",
          lg: "11px",
        },
        width: {
          xs: "28px",
          sm: "35px",
          md: "40px",
          lg: "40px",
        },
        height: {
          xs: "28px",
          sm: "35px",
          md: "40px",
          lg: "40px",
        },
      }}>
        <AudomasLogo style={{width: "100%", height: "100%"}}/>
      </Box>
      <Typography variant="h2">
          Audomas
      </Typography>
    </Box>
  )
}
export default Logo;
